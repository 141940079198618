<template>
  <div class="meter-settings pb-10">
    <v-row>
      <v-spacer></v-spacer>
      <v-col
        class="text-right mt-3 mr-8"
        cols="6"
      >
        <v-btn
          v-if="can('sms-create')"
          color="primary"
          @click="showAddMeterDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiAccountMultiplePlus }}
          </v-icon>
          Add Permitted Meter
        </v-btn>
      </v-col>
    </v-row>
    <v-card-title>
      Permitted Meters
      <v-spacer></v-spacer>
      <v-col class="col-md-4">
        <search-input v-model="search" />
      </v-col>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="meters"
      :loading="isMetersLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      hide-default-footer
    >
      <template v-slot:progress>
        <v-progress-linear
          indeterminate
          class="mt-2"
          rounded
        ></v-progress-linear>
        <p class="text-center mt-1">
          Loading...Please wait
        </p>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="meters.length > 0">
          <tr
            v-for="meter in items"
            :key="meter.id"
          >
            <td>{{ meter.number }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    ma-2
                    v-bind="attrs"
                    text
                    icon
                    @click="showDeleteMeterDialog(meter.id, meter.number)"
                    v-on="on"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-show="!isMetersLoading">
            <td
              :colspan="headers.length"
              style="text-align: center"
            >
              No permitted meters found
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-divider />
    <v-pagination
      v-model="pagination.page"
      class="mt-1"
      :length="pagination.pageCount"
      :total-visible="8"
      @input="onPageChange"
    ></v-pagination>
    <add-valid-meter-dialog
      :show-dialog="showAddMeterDialog"
      :meter="meter"
      @close="
        showAddMeterDialog = false
        meter = {}
      "
      @valid-meter-added="getMeters()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      :message="deleteMeterMessage"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteMeter()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Form from 'vform'
import {
  mdiMagnify, mdiDelete, mdiAccountMultiplePlus,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import SearchInput from '@/components/partials/SearchInput.vue'
import AddValidMeterDialog from '@/components/dialogs/AddValidMeterDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    SearchInput,
    AddValidMeterDialog,
    ConfirmDialog,
  },
  mixins: [hasPermission],
  data() {
    return {
      sortBy: ['created_at'],
      sortDesc: [true],
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Number',
          align: 'start',
          value: 'number',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      isLoading: true,
      meter: {},
      meters: [],
      meterUrl: 'valid-meters',
      isMetersLoading: false,
      showAddMeterDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: null,
      toDeleteMeterNumber: null,
      form: new Form({
        id: '',
        name: '',
        email: '',
        phone: '',
      }),
      icons: {
        mdiMagnify,
        mdiDelete,
        mdiAccountMultiplePlus,
      },
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.selectedStation}|${this.meterUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    deleteMeterMessage() {
      return `Are you sure you want to delete meter number ${this.toDeleteMeterNumber} from permitted meters`
    },
  },
  mounted() {
    this.getMeters()
  },
  methods: {
    editMeter(meter) {
      this.meter = meter
      this.showAddMeterDialog = true
    },
    showDeleteMeterDialog(id, number) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
      this.toDeleteMeterNumber = number
    },
    getMeters: _.debounce(function () {
      this.isMetersLoading = true
      axios
        .get(
          `${this.meterUrl}?sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&page=${this.pagination.page}`,
        )
        .then(response => {
          this.meters = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isMetersLoading = false
        })
        .catch(error => {
          this.isMetersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    deleteMeter() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`valid-meters/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Meter deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getMeters()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onPageChange() {
      this.getMeters()
    },
  },
}
</script>
