<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Permitted Meter</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row
                v-for="(number, index) in form.numbers"
                :key="index"
              >
                <v-col cols="8">
                  <v-text-field
                    v-model="form.numbers[index]"
                    label="Meter number"
                    placeholder="0000******"
                    :rules="numberRulesRequired"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-btn
                    v-show="index === 0"
                    small
                    outlined
                    fab
                    color="primary"
                    @click="addNumberInput"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-show="index != 0"
                    small
                    outlined
                    fab
                    color="primary"
                    @click="removeNumberInput(index)"
                  >
                    <v-icon>{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <small
                v-show="form.errors.has('number')"
                class="validation-error"
              >{{
                form.errors.get('number')
              }}</small>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import { mdiPlus, mdiClose } from '@mdi/js'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    meter: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      numbers: [''],
    }),
    icons: {
      mdiPlus,
      mdiClose,
    },
    isRolesLoading: false,
    roles: [],
    action: 'Save',
  }),
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    meter() {
      if (Object.keys(this.meter).length > 0) {
        this.form.update(this.meter)
        this.getMeter(this.form.meter_id)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveMeter()
        } else {
          this.updateMeter()
        }
      }
    },
    saveMeter() {
      this.form
        .post('valid-meters')
        .then(() => {
          this.$notification.success('Permitted Meter added successfully')
          this.$emit('close')
          this.$emit('valid-meter-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    addNumberInput() {
      this.form.numbers.push('')
    },
    removeNumberInput(index) {
      this.form.numbers.splice(index, 1)
    },
  },
}
</script>
